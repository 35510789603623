<template>
  <div class="intro" :style="{ paddingBottom: dynamicPadding + 'px' }">
    <section class="topic-block">
      <div class="bg-white">
        <LazyShow>
          <div class="logo">
            <img :src="imgSrc.Logo" alt="logo">
          </div>
        </LazyShow>
        <LazyShow>
          <h2 class="title fs-h2 text-center">掌握你的衣櫥，購物更精準</h2>
        </LazyShow>
        <lazy-show class="d-flex justify-content-center">
          <div class="desc fs-16px fw-bold text-center">即時比對衣櫥中相似的單品，讓你購衣時不再與自己撞衫</div>
        </lazy-show>
      </div>
      <LazyShow class="z-index-0">
        <div class="bg-beige">
          <p class="fs-16px text-center">時間與空間，比衣服更珍貴</p>
          <p class="fs-16px text-center">我們不該把它浪費在同樣的款式上</p>
          <p class="fs-16px text-center text-underline">下載長腳衣櫥APP，讓你在逛街時</p>
          <p class="fs-16px text-center text-underline">快速比對你衣櫥裡相似的單品</p>
          <p class="fs-16px text-center text-underline">不再購買重複的衣服</p>
          <div class="schematic-diagram">
            <img :src="imgSrc.IntroImage01" alt="示意圖">
          </div>
        </div>
      </LazyShow>
    </section>
    <section class="slogan-block">
      <lazy-show>
        <h2 class="title fs-h2">「讓你的衣服長腳，帶你走出買錯衣服的懊悔」</h2>
      </lazy-show>
    </section>
    <section class="function-display-block">
      <div class="function-item">
        <lazy-show>
          <div class="item-image">
            <img :src="imgSrc.IntroImage02" alt="功能示意圖">
          </div>
        </lazy-show>
        <lazy-show class="d-flex">
          <div class="item-name online-wardrobe fs-h4">線上衣櫥</div>
        </lazy-show>
        <lazy-show>
          <h3 class="item-title fs-h3">快速搜尋相似的單品</h3>
        </lazy-show>
        <lazy-show>
          <h4 class="item-hashtag online-wardrobe fs-h4">#分類、顏色、版型一鍵搜</h4>
        </lazy-show>
        <lazy-show>
          <div class="item-desc fs-16px">
            逛街時，可透過衣物分類、顏色、版型及品牌的篩選，一鍵找出你目前衣櫃中已有的雷同單品有哪些，作為購買的參考依據。
          </div>
        </lazy-show>
      </div>
      <div class="function-item">
        <lazy-show>
          <div class="item-image">
            <img :src="imgSrc.IntroImage03" alt="功能示意圖">
          </div>
        </lazy-show>
        <lazy-show class="d-flex">
          <div class="item-name body-data fs-h4">身體數據</div>
        </lazy-show>
        <lazy-show>
          <h3 class="item-title fs-h3">記錄你的身型與尺寸</h3>
        </lazy-show>
        <lazy-show>
          <h4 class="item-hashtag body-data fs-h4">#肩寬、腰圍、腿長全都錄</h4>
        </lazy-show>
        <lazy-show>
          <div class="item-desc fs-16px">
            購衣時，各種品牌的尺寸，身體的各種數據都不同，多到容易搞混。長腳衣櫥幫你全都記錄下來，方便購物時即時參考。
          </div>
        </lazy-show>
      </div>
      <div class="function-item">
        <lazy-show>
          <div class="item-image">
            <img :src="imgSrc.IntroImage04" alt="功能示意圖">
          </div>
        </lazy-show>
        <lazy-show class="d-flex">
          <div class="item-name wardrobe-data fs-h4">衣櫥數據</div>
        </lazy-show>
        <lazy-show>
          <h3 class="item-title fs-h3">分析數據勇敢斷捨離</h3>
        </lazy-show>
        <lazy-show>
          <h4 class="item-hashtag wardrobe-data fs-h4">#穿著次數、類別件數、色彩統計一次看</h4>
        </lazy-show>
        <lazy-show>
          <div class="item-desc fs-16px">
            透過單品穿著次數、類別件數統計、色彩統計等衣櫥數據，幫你找到衣櫥裡「多」的那一件。
          </div>
        </lazy-show>
      </div>
    </section>
    <CustomModalFooter ref="bottomBtnGroup" class="form-footer flex-nowrap">
      <div>
        <div>
          <b-button variant="primary" class="w-100" block @click="navigateToLogin">
            <span>註冊/登入</span>
          </b-button>
        </div>
        <div class="mt-2" v-if="!isInStandaloneMode">
          <b-button variant="outline-primary" class="w-100" block @click="toInstallPage">
            <span>安裝長腳衣櫥App</span>
          </b-button>
        </div>
      </div>
    </CustomModalFooter>
  </div>
</template>

<script>
import LazyShow from "@/components/commons/LazyShow.vue";
import CustomModalFooter from "@/components/CustomModalFooter.vue";
import Logo from "@/assets/logos/logo.svg";
import IntroImage01 from "@/assets/images/intro/intro-image-01.png";
import IntroImage02 from "@/assets/images/intro/intro-image-02.jpeg";
import IntroImage03 from "@/assets/images/intro/intro-image-03.jpeg";
import IntroImage04 from "@/assets/images/intro/intro-image-04.jpeg";

export default {
  name: 'Intro',
  data() {
    return {
      dynamicPadding: 0,
      imgSrc: {
        Logo: Logo,
        IntroImage01: IntroImage01,
        IntroImage02: IntroImage02,
        IntroImage03: IntroImage03,
        IntroImage04: IntroImage04,
      }
    };
  },
  props: {
  },
  components: {
    LazyShow,
    CustomModalFooter,
  },
  computed: {
    isInStandaloneMode: {
			get() {
				const mqStandAlone = '(display-mode: fullscreen)';
				// const mqStandAlone = '(display-mode: standalone)';
				return (window.navigator.standalone || window.matchMedia(mqStandAlone).matches);
				// return window.matchMedia('(display-mode: standalone)').matches;
			},
		},
		isIOS: {
			get() {
				const ua = window.navigator.userAgent.toLowerCase();
				console.log(ua);
				return (ua.indexOf('iphone') !== -1) || (ua.indexOf('ipad') !== -1);
			}
		},
	},
  watch: {
  },
  mounted() {
    this.updatePadding();
	},
  methods: {
    updatePadding() {
      if (this.$refs.bottomBtnGroup) {
        const targetHeight = this.$refs.bottomBtnGroup.$el.clientHeight;
        console.log(this.$refs.bottomBtnGroup.$el.clientHeight);
        this.dynamicPadding = targetHeight;
      }
    },
    navigateToLogin() {
      this.$router.push({ name: 'Login' });
    },
    toInstallPage() {
			if (this.isIOS) {
				window.open('/closet/install_desc_IOS', '_blank');
			} else {
				window.open('/closet/install_desc_android', '_blank');
			}
		},
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.intro {
  .topic-block {
    position: relative;
    color: $typography-primary-default;
    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      img {
        width: 38%;
        object-fit: contain;
      }
    }
    .desc {
      width: 70%;
      color: $color-primary;
      filter: brightness(60%);
    }
    .bg-white {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $color-white;
      padding: 3rem 1rem 0;
      //background:
      //    radial-gradient(closest-side, $color-white 99%, transparent 100%) center bottom/100% 52px no-repeat,
      //      /* 上半部分，橢圓形部分 */
      //    linear-gradient($color-white, $color-white) 0 0/100% calc(100% - 26px) no-repeat,
      //      /* 下半部分，純色填充 */
      //    linear-gradient(#F4EEE9, #F4EEE9) 0 100%/100% 20px no-repeat;
    }
    .bg-beige {
      position: relative;
      background: #F4EEE9;
      padding-top: 3.5rem;
      &:before {
        position: absolute;
        top: -28px;
        left: 0;
        content: '';
        background: $color-white;
        width: 100%;
        height: 56px;
        border-radius: 100%;
      }
    }
    .schematic-diagram {
      display: flex;
      justify-content: center;
      padding: .5rem 0 2rem;
      img {
        width: 48%;
      }
    }
    .z-index-0 {
      position: relative;
      z-index: 0;
    }
  }
  .slogan-block {
    display: flex;
    justify-content: center;
    color: $color-white;
    background: $typography-primary-default;
    padding: 2rem;
    .fs-h2 {
      margin: 0;
    }
  }
  .function-display-block {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    color: $typography-primary-default;
    padding: 3rem;
    .function-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      .item-image {
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        border-radius: 1rem;
        overflow: hidden;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .item-name {
        color: $color-white;
        letter-spacing: .125rem;
        border-radius: .5rem;
        padding: .25rem .75rem;
        margin: 0;
        &.online-wardrobe {
          background: #6096A6;
        }
        &.body-data {
          background: #A7464B;
        }
        &.wardrobe-data {
          background: #519864;
        }
      }
      .item-title {
        margin: 0;
      }
      .item-hashtag {
        line-height: 1rem;
        letter-spacing: .125rem;
        margin: 0;
        &.online-wardrobe {
          color: #6096A6;
        }
        &.body-data {
          color: #A7464B;
        }
        &.wardrobe-data {
          color: #519864;
        }
      }
      .item-desc {
        text-align: justify;
      }
    }
  }
  .text-underline {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
</style>
