// 使用方法參考
// mixins: [observeVisibilityMixin], // 引入 Mixin
// mounted() {
//     this.useObserver(
//         this.$refs.myElement, // 要觀察的元素
//         () => {
//             console.log('元素已可見');
//             this.$refs.myElement.classList.add('fade-in'); // 執行動畫效果
//         }
//     );
// },

export default {
    methods: {
        useObserver(targetElement, onVisibleCallback, options = { root: null, threshold: 0.1 }) {
            if (!targetElement) {
                console.warn('必須提供目標元素給 observeVisibility。');
                return;
            }
            const observer = new IntersectionObserver(([entry], obs) => {
                if (entry.isIntersecting) {
                    onVisibleCallback(entry);
                    obs.disconnect(); // 停止觀察，避免重複觸發
                }
            }, options);

            observer.observe(targetElement);
        },
    },
};