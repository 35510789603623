<template>
  <div class="lazy-show" :class="{ 'is-visible': isVisible }">
    <slot />
  </div>
</template>

<script>
import observeVisibilityMixin from "@/assets/js/observeVisibilityMixin";
export default {
  name: 'LazyShow',
  mixins: [observeVisibilityMixin],
  data() {
    return {
      isVisible: false, // 控制 slot 是否顯示
    };
  },
  props: {
  },
  computed: {
  },
  mounted() {
    this.useObserver(
        this.$el,
        () => {
          this.isVisible = true;
        }
    );
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import "src/assets/scss/basic";
.lazy-show {
  width: 100%;
  /* 確保元素可被檢測到 */
  min-height: 1px;
  transition: all .6s ease-out;
  transform: translateY(20px);
  opacity: 0;
  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
